import Empty from "@/components/global/Empty";
import NoPage from "@/components/icons/NoPage";

import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

const NotFound = () => {
  const { t } = useTranslation("common");
  return (
    <div className="flex flex-col items-center justify-center h-[70vh] sm:h-screen">
      <Empty
        icon={<NoPage className="h-[150px] sm:h-56 w-[190px] sm:w-60" />}
        title={t("404 ERROR PAGE")}
        description={t('PageNotFoundDescription')}
      ></Empty>
    </div>
  );
};

export default NotFound;

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}
