const Empty = ({
  children,
  data = null,
  icon,
  title,
  description,
  sectionClassName,
  titleClassName,
  descriptionClassName,
  buttonClassName,
  buttonTitle,
  buttonFunc,
}) => {
  if (!data) {
    return (
      <section
        className={
          sectionClassName ||
          'mx-auto my-20 flex w-11/12 flex-col items-center justify-center max-sm:h-[60vh] sm:my-28'
        }
      >
        {icon}
        <p
          className={
            titleClassName ||
            'mt-8 text-[26px] font-semibold max-sm:text-[15px] sm:mt-12 sm:font-semibold'
          }
        >
          {title}
        </p>
        <p
          className={
            descriptionClassName ||
            'mb-3 mt-4 text-center text-xl text-[#7D7474] max-sm:text-xs sm:mt-6 md:max-w-[640px]'
          }
        >
          {description}
        </p>
        {buttonTitle ? (
          <button className={buttonClassName} onClick={buttonFunc}>
            {buttonTitle}
          </button>
        ) : null}
      </section>
    );
  } else {
    return <>{children}</>;
  }
};

export default Empty;
